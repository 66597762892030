<script
    lang="ts"
    setup
>
    import type { ChatMessage, ChatUploadPhotoModalContext } from '~/ts/types/chat'
    import { ChatMessageTypeEnum, ChatStatusEnum } from '~/ts/enums/chat'
    import { useChatStore } from '~/stores/chat'
    import { useUserStore } from '~/stores/user'
    import { MessagesReader } from '~/helpers/chat/MessagesReader'
    import { InfiniteScroll } from '~/helpers/chat/InfiniteScroll'
    import { MessagesObserver } from '~/helpers/chat/MessagesObserver'
    import isOwnMessage from '~/helpers/chat/isOwnMessage'
    import findFirstUnreadMessageId from '~/helpers/chat/findFirstUnreadMessageId'
    import { CChannelTypeEnum } from '~/ts/enums/communication-channel'
    // Має бути явно імпортовано через незрозумілу відсутність інколи
    import { CHAT_SCROLL_BODY_ANCHOR_TOP } from '~/constants/chat'

    type LoadMessagesPayload = {
        callback?: (messages: ChatMessage[]) => void
        preserveScroll?: boolean
        replaceFirstUnreadMessageId?: boolean
    }

    type ScrollBottomPayload = {
        callback?: Function
        anchorEl?: HTMLElement
        silentScroll?: boolean
        smoothAtEnd?: boolean
    }

    const chatStore = useChatStore()
    const userStore = useUserStore()

    const { computedScopeCache } = useScopeCache()

    const chatBodyRef = ref<HTMLDivElement>()

    const currentChat = chatStore.currentChat

    const { isTabActive } = useTabActivity()

    const messagesLoading = ref<boolean>(false)
    const firstUnreadMessageId = ref<string | undefined>()
    const showScrollDownButton = ref<boolean>(false)
    const needToPreload: boolean = (
        !currentChat.noMoreMessages && currentChat.messages.length < CHAT_MESSAGES_PER_PAGE
    )
    const hideMessages = ref<boolean>(true)
    const uploadPhotoModalContext = ref<ChatUploadPhotoModalContext>()

    const loadMessages = async (payload: LoadMessagesPayload = { preserveScroll: true }): Promise<void> => {
        if (messagesLoading.value || currentChat.noMoreMessages) {
            return
        }

        messagesLoading.value = true

        const { data, error } = await useApi().chat.chatMessages({
            chatId: currentChat.id,
            beforeId: currentChat.messages[0]?._id
        })

        if (error.value) {
            messagesLoading.value = false

            return
        }

        if (data.value.items.length < CHAT_MESSAGES_PER_PAGE) {
            currentChat.noMoreMessages = true
        }

        ensureFirstUnreadMessageId(data.value.items)

        const unshiftMessages = (): void => {
            chatStore.insertMessagesIntoChat(
                currentChat,
                data.value.items,
                { firstUnreadId: firstUnreadMessageId.value }
            )
        }

        if (payload.preserveScroll) {
            await preserveScrollPosition(unshiftMessages)
        } else {
            unshiftMessages()
        }

        await nextTick(() => {
            messagesLoading.value = false

            setTimeout(() => payload.callback?.(currentChat.messages), 0)
        })
    }

    const scrollBody = (payload: ScrollBottomPayload = {}): void => {
        const scrollTop = (top: number): void => {
            if (payload.smoothAtEnd) {
                top -= CHAT_SCROLL_DOWN_BUTTON_THRESHOLD

                chatBodyRef.value.scrollTop = top

                animate({
                    duration: 0.2,
                    timing: animationTiming.makeEaseOut(animationTiming.circ),
                    draw(progress) {
                        chatBodyRef.value.scrollTop = top
                            + Math.round(CHAT_SCROLL_DOWN_BUTTON_THRESHOLD * progress)
                    }
                })
            } else {
                chatBodyRef.value.scrollTop = top
            }
        }

        payload.silentScroll && infiniteScroll.deactivate()

        const done = (): void => {
            payload.silentScroll && infiniteScroll.activate()

            payload.callback?.()
        }

        nextTick(() => {
            if (!chatBodyRef.value) {
                done()

                return
            }

            scrollTop(
                payload.anchorEl
                    ? (payload.anchorEl.offsetTop - CHAT_SCROLL_BODY_ANCHOR_TOP)
                    : (chatBodyRef.value.scrollHeight - chatBodyRef.value.clientHeight)
            )

            setTimeout(done, 0)
        })
    }

    const preserveScrollPosition = async (callback: Function): Promise<void> => {
        if (!chatBodyRef.value) {
            await callback()

            return
        }

        infiniteScroll.deactivate()

        const previous = {
            scrollHeight: chatBodyRef.value.scrollHeight || 0,
            scrollTop: chatBodyRef.value.scrollTop || 0
        }

        await callback()

        await nextTick(() => {
            chatBodyRef.value.scrollTop = chatBodyRef.value.scrollHeight - previous.scrollHeight + previous.scrollTop

            infiniteScroll.activate()
        })
    }

    const chatBodyOnScroll = ({ target }): void => {
        if (!target) {
            return
        }

        infiniteScroll.onScroll()

        const showScrollDownButtonValue = target.scrollHeight
            - (target.scrollTop + target.clientHeight)
            > CHAT_SCROLL_DOWN_BUTTON_THRESHOLD

        if (showScrollDownButtonValue !== showScrollDownButton.value) {
            showScrollDownButton.value = showScrollDownButtonValue
        }
    }

    const isMessageChangeable = (message: ChatMessage) => computedScopeCache<boolean>(
        message._id + ':changeable',
        () => {
            return currentChat.status === ChatStatusEnum.Active
                && message.type !== ChatMessageTypeEnum.Log
                && isOwnMessage(message, userStore.currentOperator)
        }
    )

    const onScrollDownButtonClick = async (): Promise<void> => {
        scrollBody({ smoothAtEnd: true })

        await new Promise(resolve => setTimeout(resolve, 0))

        const messageToRead = ((): ChatMessage | undefined => {
            let lastIndex = currentChat.messages.length - 1

            while (lastIndex) {
                const message = currentChat.messages[lastIndex]

                if (!isOwnMessage(message, userStore.currentOperator)) {
                    return !message.viewed_at ? message : undefined
                }

                lastIndex--
            }
        })()

        if (messageToRead) {
            messagesReader.read(currentChat, messageToRead._id)
        }
    }

    const ensureFirstUnreadMessageId = (messages: ChatMessage[] = currentChat.messages): void => {
        firstUnreadMessageId.value = findFirstUnreadMessageId(
            messages,
            userStore.currentOperator,
            currentChat.noMoreMessages
        )
    }

    const messagesReader = new MessagesReader(
        userStore.currentOperator,
        (count) => {
            currentChat.numberOfUnreadMessages = currentChat.numberOfUnreadMessages - count
        }
    )

    const infiniteScroll: InfiniteScroll = new InfiniteScroll(
        chatBodyRef,
        computed({
            get: () => currentChat.noMoreMessages,
            set: v => (currentChat.noMoreMessages = v)
        }),
        messagesLoading,
        () => loadMessages()
    )

    const messagesObserver: MessagesObserver = new MessagesObserver(
        chatBodyRef,
        (messageId: string) => {
            if (!currentChat.chatTransfer) {
                messagesReader.read(currentChat, messageId)
            }
        }
    )

    const bus = useEventBus()

    const busCleaners = [
        bus.on(BUS_EVENT_CHAT_SCROLL_BODY, () => scrollBody({ silentScroll: true })),
        bus.on(BUS_EVENT_CHAT_SCROLL_BODY_TO_MESSAGE, (id) => {
            scrollBody({
                anchorEl: chatBodyRef.value.querySelector(`[data-id='${ id }']`),
                silentScroll: true
            })
        }),
        bus.on(BUS_EVENT_CHAT_OPEN_UPLOAD_PHOTO_MODAL, (value: ChatUploadPhotoModalContext) => {
            uploadPhotoModalContext.value = value
        })
    ]

    onMounted(async () => {
        await nextTick()

        const onReady = async (): Promise<void> => {
            await new Promise(resolve => chatBodyRef.value && scrollBody({
                anchorEl: chatBodyRef.value.querySelector(`#${ CHAT_UNREAD_DIVIDER_ID }`),
                callback: resolve
            }))

            infiniteScroll.activate()

            if (currentChat.status === ChatStatusEnum.Active) {
                messagesObserver.run()
            }

            hideMessages.value = false
        }

        if (needToPreload) {
            await loadMessages({
                preserveScroll: false,
                callback: onReady
            })
        } else {
            ensureFirstUnreadMessageId()

            await onReady()
        }
    })

    onMounted(() => {
        watch(currentChat.messages, debounceFn(() => {
            if (isTabActive.value) {
                return
            }

            ensureFirstUnreadMessageId()

            nextTick(() => {
                if (firstUnreadMessageId.value && chatBodyRef.value) {
                    scrollBody({ anchorEl: chatBodyRef.value.querySelector(`#${ CHAT_UNREAD_DIVIDER_ID }`) })
                }
            })
        }, 300))
    })

    onBeforeUnmount(() => {
        messagesObserver.stop()

        busCleaners.forEach(cb => cb())
    })
</script>

<template>
    <LazyChatConversationBodyUploadPhotoModal
        v-if="uploadPhotoModalContext"
        key="upload-photo-modal"
        :context="uploadPhotoModalContext"
        @close="uploadPhotoModalContext = undefined"
    />

    <AppScrollGradientContainer
        v-slot="{ slotRef }"
        class="scrollbar-hide overflow-hidden flex flex-col flex-1 justify-end"
    >
        <div
            key="body"
            :ref="el => { chatBodyRef = el as any; slotRef(el) }"
            class="scrollbar-hide overflow-y-auto"
            data-chat-conversation-body=""
            @scroll.passive="chatBodyOnScroll"
        >
            <ClientOnly>
                <ChatUISpinner
                    v-if="messagesLoading"
                    key="loader"
                />

                <Teleport
                    key="scroll-down-teleport"
                    to="#chat-conversation-footer__scroll-down-button"
                >
                    <ChatConversationBodyScrollDownButton
                        v-show="showScrollDownButton"
                        key="scroll-down"
                        @click="onScrollDownButtonClick()"
                    />
                </Teleport>

                <div
                    key="messages"
                    :class="[
                        `
                            flex
                            flex-col
                            p-[0_16px_30px_16px]
                            transition-[opacity_var(--transition-default-duration-with-ease)]
                        `,
                        { 'opacity-0': hideMessages },
                        { 'pointer-events-none': infiniteScroll.isScrolling.value }
                    ]"
                >
                    <div
                        v-for="dateGroup in currentChat.groupedMessages"
                        :key="dateGroup.dayStartAt"
                        class="flex flex-col gap-1"
                    >
                        <ChatConversationBodyMessageDate
                            :date-group="dateGroup"
                            :container="chatBodyRef"
                            :is-container-scrolling="infiniteScroll.isScrollingDebounced.value"
                            @observe="(a, b) => messagesObserver.observe(a, b)"
                            @unobserve="messagesObserver.unobserve($event)"
                        />

                        <template
                            v-for="{ messages, key } in dateGroup.senderGroups"
                            :key="key"
                        >
                            <template
                                v-for="(message, messageIndex) in messages"
                                :key="message._id"
                            >
                                <AppDividerWithText
                                    v-if="firstUnreadMessageId && firstUnreadMessageId === message._id"
                                    :id="CHAT_UNREAD_DIVIDER_ID"
                                    key="unread-divider"
                                >
                                    {{ $t('unread-messages') }}
                                </AppDividerWithText>

                                <ChatConversationBodyMessage
                                    :message="message"
                                    :own="isOwnMessage(message, userStore.currentOperator)"
                                    :changeable="isMessageChangeable(message)"
                                    :first-in-group="messageIndex === 0"
                                    :is-viber="currentChat.channel_type === CChannelTypeEnum.Viber"
                                    @observe="(a, b) => messagesObserver.observe(a, b)"
                                    @unobserve="messagesObserver.unobserve($event)"
                                />
                            </template>
                        </template>
                    </div>
                </div>
            </ClientOnly>
        </div>
    </AppScrollGradientContainer>
</template>
