<script
    lang="ts"
    setup
>
    import type { ChatMessage, ChatMessageTextPart } from '~/ts/types/chat'
    import { ChatMessageTextPartEnum } from '~/ts/enums/chat'
    import { MessageTextParser } from '~/helpers/chat/MessageTextParser'

    type Props = {
        message: ChatMessage
    }

    const props = defineProps<Props>()

    const parseWithCache = useCacheFn<ChatMessageTextPart[]>(
        CACHE_CHAT_MESSAGES_PREFIX + props.message._id,
        () => MessageTextParser.parse(props.message.text)
    )

    const textParts = computed<ChatMessageTextPart[]>(parseWithCache)
</script>

<template>
    <template
        v-for="({ type, content }, index) in textParts"
        :key="index"
    >
        <ChatUILink
            v-if="type === ChatMessageTextPartEnum.Link"
            :key="'link-' + type + index"
            :url="content"
        />

        <ChatUIEmoji
            v-else-if="type === ChatMessageTextPartEnum.Emoji"
            :key="'emoji-' + type + index"
        >
            {{ content }}
        </ChatUIEmoji>

        <br
            v-else-if="type === ChatMessageTextPartEnum.LineBreak"
            :key="'br-' + type + index"
        />

        <template v-else>
            {{ content }}
        </template>
    </template>
</template>
