<script
    lang="ts"
    setup
>
    import type { ChatTypingContext } from '~/ts/types/chat'
    import { useUserStore } from '~/stores/user'
    import { useChatStore } from '~/stores/chat'

    const userStore = useUserStore()
    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat

    const containerClass = computed<string>(() => {
        let classes = `
            z-[2]
            absolute
            flex
            items-center
            left-0
            tablet:left-3
            max-tablet:mx-4
            p-1
            bg-[#f6f7f8]
            text-[14px]
            text-[#8a8f9e]
            leading-[120%]
            rounded-[4px]
            transition-[bottom_var(--transition-chat-footer-outset-el-duration)_var(--transition-default-ease)]
        `

        if (currentChat.editableMessage) {
            classes += ' bottom-[calc(100%+60px)]'
        } else {
            classes += ' bottom-[calc(100%+4px)]'
        }

        return classes
    })

    const typingContext = computed<ChatTypingContext>(() => {
        return chatStore.chatTypingContexts[currentChat.id]
    })
</script>

<template>
    <Transition name="client-writes-transition">
        <div
            v-if="typingContext.typing"
            key="client-writes"
            :class="containerClass"
        >
            <span key="text">
                {{ typingContext.text }}... ({{ $t('chat-client-writes-hint') }})
            </span>

            <AppTooltipProRestriction
                v-if="!userStore.activeSubscription"
                key="lock"
                :tooltip-args="{ offsetX: 110, tailOffsetX: 110 }"
            >
                <template #activator="{ open, close }">
                    <AppIconLock
                        size="16"
                        class="ml-2"
                        @mouseenter.passive="open"
                        @mouseleave.passive="close"
                        @click="open"
                    />
                </template>
            </AppTooltipProRestriction>
        </div>
    </Transition>
</template>

<style lang="sass">
    .client-writes-transition
        &-enter-from,
        &-leave-to
            opacity: 0
            transform: scaleY(0.8) translateY(6px)

        &-enter-active,
        &-leave-active
            pointer-events: none
            transform-origin: 0 100%
            transition: opacity 160ms var(--transition-default-ease), transform 160ms var(--transition-default-ease) !important

        &-enter-to,
        &-leave-from
            opacity: 1
            transform: scaleY(1) translateY(0)
</style>
