<script
    lang="ts"
    setup
>
    import type { ChatMessage, ChatMessageConfig } from '~/ts/types/chat'

    type Props = {
        message: ChatMessage
        messageConfig: ChatMessageConfig
    }

    const props = defineProps<Props>()
</script>

<template>
    <ChatConversationBodyMessageHead
        v-if="props.messageConfig.in && props.messageConfig.firstInGroup"
        key="head"
        :message="props.message"
        :message-config="props.messageConfig"
    />

    <ChatConversationBodyMessageContent
        key="content"
        :message-config="props.messageConfig"
    >
        <AppLink
            wrapper
            target="_blank"
            :to="{
                name: 'p-pid-knowledge-base',
                query: { id: props.message.article?._id }
            }"
        >
            <ChatConversationBodyMessageContentBubble :message-config="props.messageConfig">
                <div :class="$style['chat-message__content__bubble__article-icon']">
                    <LazyAppIconBooks color="#30353c"/>
                </div>

                <div :class="$style['chat-message__content__bubble__article-text']">
                    {{ props.message.article?.title || $t('article-not-available') }}
                </div>
            </ChatConversationBodyMessageContentBubble>
        </AppLink>

        <ChatConversationBodyMessageDetails
            :message="props.message"
            :message-config="props.messageConfig"
        />
    </ChatConversationBodyMessageContent>
</template>

<style
    lang="sass"
    module
    scoped
>
    .chat-message__content__bubble__article-icon
        display: flex
        align-items: center
        justify-content: center
        min-width: 44px
        min-height: 44px
        margin-left: -6px
        margin-right: 10px
        background: #e3e5eb
        border-radius: 4px

    .chat-message__content__bubble__article-text
        line-height: 130%
        font-size: 14px
        font-weight: 500
</style>
