<script
    lang="ts"
    setup
>
    const { maxTablet } = useWindowSize()
</script>

<template>
    <div class="flex items-center gap-2">
        <ChatConversationHeaderActionsTransfer>
            <template #activator="{ open, disabled }">
                <AppButtonIcon
                    small
                    :disabled="disabled"
                    @click="open()"
                >
                    <AppIconUserSwitch
                        size="20"
                        :color="disabled ? '#a7a7a7' : '#000'"
                    />
                </AppButtonIcon>
            </template>
        </ChatConversationHeaderActionsTransfer>

        <ChatConversationHeaderActionsClose>
            <template #activator="{ open, disabled }">
                <AppButton
                    :small="maxTablet"
                    secondary
                    :disabled="disabled"
                    @click="open()"
                >
                    {{ $t('chat-actions-close') }}
                </AppButton>
            </template>
        </ChatConversationHeaderActionsClose>
    </div>
</template>
