<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H12.0067C12.0633 17.1633 12.4679 16.4696 12.9222 16H5.2L4 17.2V4H20V11.4322C20.448 11.632 20.8628 11.8947 21.2266 12.2065C21.5083 12.448 21.772 12.7309 22 13.0503V4C22 2.9 21.1 2 20 2ZM17 11H15V9H17V11ZM13 11H11V9H13V11ZM9 11H7V9H9V11ZM22 21.8V18.3C22 17.6 21.4 17 20.8 17V15.5C20.8 14.1 19.4 13 18 13C16.6 13 15.2 14.1 15.2 15.5V17C14.6 17 14 17.6 14 18.2V21.7C14 22.4 14.6 23 15.2 23H20.7C21.4 23 22 22.4 22 21.8ZM18 14.2002C18.8 14.2002 19.5 14.7002 19.5 15.5002V17.0002H16.5V15.5002C16.5 14.7002 17.2 14.2002 18 14.2002Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
