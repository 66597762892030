<script
    lang="ts"
    setup
>
    import { useSettingStore } from '~/stores/setting'
    import { AppItemPhoneCountryIndexEnum } from '~/ts/enums/app'

    type Props = {
        name: string
        iso?: string
    }

    const props = defineProps<Props>()

    const settingStore = useSettingStore()

    await settingStore.fillPhoneCountries()

    const phoneCountry = computed<any>(() => {
        if (!props.iso) {
            return
        }

        return settingStore.phoneCountriesByCode[props.iso]
    })
</script>

<template>
    <span class="relative inline-flex items-center pl-7">
        <span
            v-if="props.iso"
            key="iso"
            class="absolute left-0"
        >
            <!-- eslint-disable vue/no-v-html -->
            <span
                class="flex w-[18px]"
                v-html="phoneCountry[AppItemPhoneCountryIndexEnum.Flag]"
            ></span>
            <!-- eslint-enable vue/no-v-html -->
        </span>

        <span key="name">
            {{ props.name }}
        </span>
    </span>
</template>
