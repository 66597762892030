<script
    lang="ts"
    setup
>
    import type { ChatMessage, ChatMessageConfig } from '~/ts/types/chat'
    import type { Operator } from '~/ts/types/operator'
    import type { Visitor } from '~/ts/types/visitor'
    import getOperatorName from '~/helpers/getOperatorName'
    import getVisitorName from '~/helpers/getVisitorName'

    type Props = {
        message: ChatMessage
        messageConfig: ChatMessageConfig
    }

    const props = defineProps<Props>()

    const { t } = useLang()

    const authorName: string = (() => {
        if (props.messageConfig.isOperator) {
            return getOperatorName(props.message.senderEntity as Operator)
        }

        return getVisitorName(props.message.senderEntity as Visitor)
    })()

    const textPart = computed<string>(() => {
        return props.messageConfig.isOperator ? t('operator').toLowerCase() : ''
    })
</script>

<template>
    <div class="truncate max-w-[396px] text-[16px] font-medium leading-[120%]">
        <span key="name">
            {{ authorName }}
        </span>

        <span
            v-if="textPart"
            key="textPart"
            class="text-[#8A8F9E] font-normal"
        >
            &nbsp;

            {{ textPart }}
        </span>
    </div>
</template>
