<script
    lang="ts"
    setup
>
    import type { ChatMessage, ChatMessageConfig } from '~/ts/types/chat'
    import type { AppMediaViewerContext } from '~/ts/types/app'

    type Props = {
        message: ChatMessage
        messageConfig: ChatMessageConfig
    }

    const props = defineProps<Props>()

    const srcLoaded = ref<boolean>(process.server || !!props.message.photo.temp)

    const currentSrc = computed<string>(() => {
        return srcLoaded.value
            ? (props.message.photo.temp || props.message.photo.thumbnails.public)
            : props.message.photo.thumbnails.v60x60
    })

    const style = useCssModule()

    const imageClass: string[] = (() => {
        const classes = [ style['chat-message__content__bubble__image'] ]

        if (props.messageConfig.out) {
            classes.push(style['chat-message__content__bubble__image--out'])
        } else if (props.messageConfig.in) {
            classes.push(style['chat-message__content__bubble__image--in'])
        }

        if (props.message.text) {
            classes.push(style['chat-message__content__bubble__image--with-text'])
        }

        return classes
    })()

    const imageOnLoad = async (): Promise<void> => {
        if (srcLoaded.value) {
            return
        }

        await fileUtil.preloadImage(props.message.photo.thumbnails.public)

        setTimeout(() => (srcLoaded.value = true), 100)
    }

    const openMediaViewer = (): void => {
        const context: AppMediaViewerContext = {
            type: 'image',
            src: props.message.photo.thumbnails?.public || props.message.photo.temp,
            text: props.message.text
        }

        useEventBus().emit(BUS_EVENT_APP_OPEN_MEDIA_VIEWER, context)
    }
</script>

<template>
    <ChatConversationBodyMessageHead
        v-if="props.messageConfig.in && props.messageConfig.firstInGroup"
        key="head"
        :message="props.message"
        :message-config="props.messageConfig"
    />

    <ChatConversationBodyMessageContent
        key="content"
        :message-config="props.messageConfig"
    >
        <ChatConversationBodyMessageContentBubble
            with-media
            :message-config="props.messageConfig"
        >
            <div
                key="image"
                :class="imageClass"
                @click="openMediaViewer()"
            >
                <img
                    :class="$style['app-image']"
                    :src="currentSrc"
                    alt="Chat message image"
                    loading="lazy"
                    decoding="async"
                    draggable="false"
                    @load="imageOnLoad()"
                />
            </div>

            <div
                v-if="props.message.text"
                key="text"
                :class="$style['chat-message__content__bubble__text']"
            >
                <ChatConversationBodyMessageParsedText :message="props.message"/>
            </div>
        </ChatConversationBodyMessageContentBubble>

        <ChatConversationBodyMessageDetails
            :message="props.message"
            :message-config="props.messageConfig"
        />
    </ChatConversationBodyMessageContent>
</template>

<style
    lang="sass"
    module
    scoped
>
    .chat-message__content__bubble__image
        $size: 250px

        &--in
            --br-top-left: 0

        &--out
            --br-top-right: 0

        &--with-text
            --br-bottom-left: 0
            --br-bottom-right: 0

        overflow: hidden
        position: relative
        max-width: $size
        max-height: $size
        min-width: $size
        min-height: $size
        border-radius: var(--br-top-left, 8px) var(--br-top-right, 8px) var(--br-bottom-right, 8px) var(--br-bottom-left, 8px)
        cursor: pointer

        > img
            position: absolute
            object-fit: cover
            max-width: 100%
            width: 100%
            height: 100%
            -webkit-user-drag: none

    .chat-message__content__bubble__text
        width: 100%
        padding: 10px 16px
        line-height: 130%
        font-size: 14px
        font-weight: 400
</style>
