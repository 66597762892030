<script
    lang="ts"
    setup
>
    import type { ChatMessage, ChatMessageConfig } from '~/ts/types/chat'
    import type { MessagesObserverMessageContext } from '~/helpers/chat/MessagesObserver'
    import { ChatMemberUserTypeEnum, ChatMessageTypeEnum } from '~/ts/enums/chat'
    import { useChatStore } from '~/stores/chat'
    import { useUserStore } from '~/stores/user'

    type Props = {
        message: ChatMessage
        own: boolean
        changeable: boolean
        firstInGroup: boolean
        isViber: boolean
    }

    type Emit = {
        (event: 'observe', el: HTMLElement, getContext: () => MessagesObserverMessageContext): void
        (event: 'unobserve', el: HTMLElement): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const userStore = useUserStore()
    const chatStore = useChatStore()

    const { t } = useLang()

    const currentChat = chatStore.currentChat

    const messageRef = ref<HTMLDivElement>()
    const menuRef = ref<ReturnType<typeof defineComponent>>()

    const pendingForDelete = ref<boolean>(false)
    const showMenu = ref<boolean>(false)

    const messageConfig = computed<ChatMessageConfig>(() => {
        const isEmoji = !!props.message.text && stringUtil.isOnlyOneEmoji(props.message.text)

        const config: ChatMessageConfig = {
            in: !props.own,
            out: props.own,
            isNote: !!props.message.is_note,
            isVisitor: props.message.sender_type === ChatMemberUserTypeEnum.Visitor,
            isOperator: props.message.sender_type === ChatMemberUserTypeEnum.Operator,
            firstInGroup: props.firstInGroup,
            log: props.message.type === ChatMessageTypeEnum.Log,
            article: props.message.type === ChatMessageTypeEnum.Article,
            image: props.message.type === ChatMessageTypeEnum.Image,
            gif: props.message.type === ChatMessageTypeEnum.Gif,
            emoji: isEmoji,
            text: !isEmoji && props.message.type === ChatMessageTypeEnum.Text,
            sending: !props.message.updated_at,
            sendingError: !!props.message.sendingError,
            sent: !!props.message.updated_at,
            viewed: !!props.message.viewed_at
        }

        return config
    })

    const containerClass = computed<string>(() => {
        let classes = 'relative flex flex-col gap-1 w-full'

        if (props.firstInGroup) {
            classes += ' mt-2'
        }

        return classes
    })

    const editMessage = (): void => {
        currentChat.editableMessage = {
            ...props.message,
            editableText: props.message.text || ''
        }
    }

    const removeMessage = async (): Promise<void> => {
        if (
            pendingForDelete.value
            || !await useConfirm({
                titleText: t('remove-message'),
                bodyText: t('remove-message-hint'),
                confirmText: t('remove')
            })
        ) {
            return
        }

        pendingForDelete.value = true

        const { error } = await useApi().chat.chatMessageDelete({
            chatId: props.message.chat_id,
            messageId: props.message._id
        })

        if (!error.value) {
            chatStore.removeChatMessage(currentChat, props.message)
        }

        pendingForDelete.value = false
    }

    onMounted(() => {
        emit(
            'observe',
            messageRef.value,
            () => ({
                message: props.message,
                messageConfig: messageConfig.value
            })
        )
    })

    onBeforeUnmount(() => emit('unobserve', messageRef.value))
</script>

<template>
    <div
        ref="messageRef"
        :class="containerClass"
        :data-id="props.message._id"
        @mouseenter.passive="showMenu = true"
        @mouseleave.passive="showMenu = false"
    >
        <ChatConversationBodyMessageMenu
            v-if="props.changeable"
            v-show="showMenu"
            key="menu"
            ref="menuRef"
            :is-viber="props.isViber"
            :editable="messageConfig.text || messageConfig.emoji"
            :restricted-by-plan="!userStore.activeSubscription"
            @edit="editMessage()"
            @remove="removeMessage()"
        />

        <ChatConversationBodyMessageTypeArticle
            v-if="messageConfig.article"
            key="article"
            :message="props.message"
            :message-config="messageConfig"
        />

        <ChatConversationBodyMessageTypeImage
            v-else-if="messageConfig.image"
            key="image"
            :message="props.message"
            :message-config="messageConfig"
        />

        <ChatConversationBodyMessageTypeGif
            v-else-if="messageConfig.gif"
            key="gif"
            :message="props.message"
            :message-config="messageConfig"
        />

        <ChatConversationBodyMessageTypeLog
            v-else-if="messageConfig.log"
            key="log"
            :message="props.message"
        />

        <ChatConversationBodyMessageTypeEmoji
            v-else-if="messageConfig.emoji"
            key="emoji"
            :message="props.message"
            :message-config="messageConfig"
        />

        <ChatConversationBodyMessageTypeText
            v-else
            key="text"
            :message="props.message"
            :message-config="messageConfig"
        />
    </div>
</template>
