<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M21 6V8H3V6H21ZM3 18H12V16H3V18ZM3 13H21V11H3V13Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
