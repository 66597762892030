<script
    lang="ts"
    setup
>
    import { useUserStore } from '~/stores/user'

    type Props = {
        editable: boolean
        restrictedByPlan: boolean
        isViber: boolean
    }

    type Emit = {
        (event: 'edit'): void
        (event: 'remove'): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const userStore = useUserStore()
</script>

<template>
    <div class="z-[1] absolute flex right-0 bottom-full pb-1">
        <AppTooltipProRestriction
            v-if="props.editable"
            key="edit"
            :tooltip-args="{
                offsetX: -78,
                tailOffsetX: -78,
                disabled: !!userStore.activeSubscription
            }"
        >
            <template #activator="{ open, close }">
                <button
                    type="button"
                    :class="[
                        'message-menu__action',
                        { '!cursor-not-allowed': !userStore.activeSubscription }
                    ]"
                    @mouseenter.passive="open"
                    @mouseleave.passive="close"
                    @click="props.restrictedByPlan || props.isViber ? open() : emit('edit')"
                >
                    <AppIconPencil size="16"/>
                </button>
            </template>

            <template
                v-if="props.isViber"
                #content-wrapper
            >
                {{ $t('viber-not-changeable-hint') }}
            </template>
        </AppTooltipProRestriction>

        <AppTooltipProRestriction
            key="remove"
            :tooltip-args="{
                offsetX: -110,
                tailOffsetX: -110,
                disabled: !!userStore.activeSubscription
            }"
        >
            <template #activator="{ open, close }">
                <button
                    type="button"
                    :class="[
                        'message-menu__action',
                        { '!cursor-not-allowed': !userStore.activeSubscription }
                    ]"
                    @mouseenter.passive="open"
                    @mouseleave.passive="close"
                    @click="props.restrictedByPlan || props.isViber ? open() : emit('remove')"
                >
                    <AppIconTrash size="16"/>
                </button>
            </template>

            <template
                v-if="props.isViber"
                #content-wrapper
            >
                {{ $t('viber-not-changeable-hint') }}
            </template>
        </AppTooltipProRestriction>
    </div>
</template>

<style
    lang="sass"
    scoped
>
    .message-menu__action
        @apply appearance-none flex items-center justify-center p-2 align-middle outline-none tracking-[0.02em] bg-white cursor-pointer

        &:hover:deep(svg > path)
            @apply fill-black #{!important}

        &:only-child
            @apply rounded-[8px]

        &:not(:only-child)
            &:first-of-type
                @apply rounded-[8px_0_0_8px]

            &:last-of-type
                @apply rounded-[0_8px_8px_0]

            &:not(:last-of-type)
                @apply border-r border-r-[#e3e5eb]
</style>
