<script
    lang="ts"
    setup
>
    type Props = {
        value: string
    }

    const props = defineProps<Props>()

    const iconComponent = computed<ReturnType<typeof defineComponent>>(() => {
        if ([ 'IOS', 'MacOS' ].includes(props.value)) {
            return resolveComponent('AppIconApple')
        }

        if (props.value === 'Android') {
            return resolveComponent('AppIconAndroid')
        }

        if (props.value === 'Linux') {
            return resolveComponent('AppIconLinux')
        }

        if (props.value.startsWith('Win')) {
            return resolveComponent('AppIconMicrosoft')
        }

        return resolveComponent('AppIconQuestion')
    })
</script>

<template>
    <span class="relative inline-flex items-center pl-6">
        <span class="absolute pb-0.5 left-0">
            <component
                :is="iconComponent"
                key="icon"
                size="18"
            />
        </span>

        {{ props.value }}
    </span>
</template>
