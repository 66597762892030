<script
    lang="ts"
    setup
>
    type Props = {
        modelValue: string
        placeholder: string
        maxHeight?: number
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
    }

    const props = withDefaults(defineProps<Props>(), {
        maxHeight: 300
    })
    const emit = defineEmits<Emit>()

    const textareaRef = ref<HTMLTextAreaElement>()

    const inputModel = useSyncProp<string>(props, 'modelValue', emit)

    const minHeight = ref<string>('auto')
    const overflow = ref<string>('hidden')

    const style = computed(() => ({
        minHeight: minHeight.value,
        overflow: overflow.value
    }))

    const resize = (): void => {
        if (!textareaRef.value) {
            return
        }

        minHeight.value = 'auto'

        nextTick(() => {
            if (textareaRef.value.scrollHeight >= props.maxHeight) {
                minHeight.value = props.maxHeight + 'px'
                overflow.value = 'auto'

                return
            }

            overflow.value = 'hidden'

            minHeight.value = textareaRef.value.scrollHeight + 'px'
        })
    }

    const getTextArea = (): HTMLTextAreaElement | null => textareaRef.value

    onMounted(() => resize())

    defineExpose({
        resize,
        getTextArea
    })
</script>

<template>
    <textarea
        ref="textareaRef"
        v-model="inputModel"
        class="
            scrollbar-hide
            appearance-none
            outline-none
            border-none
            resize-none
            w-full
            h-[18px]
            p-0
            text-[14px]
            leading-[130%]
            bg-transparent
            caret-[auto]
            placeholder-[#8a8f9e]
        "
        :placeholder="props.placeholder"
        :style="style"
        @input="resize()"
    ></textarea>
</template>
