<script
    lang="ts"
    setup
>
    import { useChatStore } from '~/stores/chat'
    import { isMacOS } from '~/utils/isMacOS'

    type Props = {
        inputModel: string
    }

    type Emit = {
        (event: 'submit'): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat

    const hintText = computed<string>(() => isMacOS() ? 'Cmd + Enter' : 'Ctrl + Enter')
</script>

<template>
    <div class="flex items-center tablet:pr-2">
        <AppHint class="whitespace-nowrap max-tablet:hidden">
            <ClientOnly>
                {{ hintText }}
            </ClientOnly>
        </AppHint>

        <Transition name="scale-transition">
            <AppButtonIcon
                v-if="props.inputModel"
                small
                class="!ml-6 !flex tablet:!hidden"
                @click="currentChat.editableMessage ? chatStore.submitEditableMessage() : emit('submit')"
            >
                <AppIconSend
                    size="20"
                />
            </AppButtonIcon>
        </Transition>

        <AppButton
            class="!ml-6 max-tablet:!hidden"
            @click="currentChat.editableMessage ? chatStore.submitEditableMessage() : emit('submit')"
        >
            {{ $t(currentChat.editableMessage ? 'edit' : 'send') }}
        </AppButton>
    </div>
</template>
