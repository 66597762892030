<script
    lang="ts"
    setup
>
    import { useChatStore } from '~/stores/chat'

    const chatStore = useChatStore()

    const showConfirm = computed<boolean>(() => {
        return chatStore.isCurrentChatNew
            || chatStore.isCurrentChatClosed
            || chatStore.isCurrentChatInTransferProcess
    })
</script>

<template>
    <div class="relative flex items-center min-h-[156px] w-full border-x-[1px] border-[#F6F7F8]">
        <ChatConversationFooterConfirm
            v-if="showConfirm"
            key="confirm"
        />

        <ChatConversationFooterInput
            v-else
            key="input"
        />

        <div id="chat-conversation-footer__scroll-down-button"></div>
    </div>
</template>
