<script
    lang="ts"
    setup
>
    import type { ChatMessage } from '~/ts/types/chat'
    import { ChatMessageTypeLogTypeEnum } from '~/ts/enums/chat'

    type Props = {
        message: ChatMessage
    }

    const props = defineProps<Props>()

    const { t } = useLang()

    const logMessage = computed<string>(() => {
        const messageTime: string = dateUtil.fromSeconds(props.message.created_at).toFormat('HH:mm')

        switch (props.message.log_type) {
            case ChatMessageTypeLogTypeEnum.Accept:
                return t(
                    'chat-message-log-type-1',
                    `<span class="font-medium">${ props.message.log_param_operator_name }</span>`,
                    messageTime
                )
            case ChatMessageTypeLogTypeEnum.Close:
                return t(
                    'chat-message-log-type-2',
                    `<span class="font-medium">${ props.message.log_param_operator_name }</span>`,
                    messageTime
                )
            case ChatMessageTypeLogTypeEnum.Reopen:
                return t(
                    'chat-message-log-type-3',
                    `<span class="font-medium">${ props.message.log_param_new_operator_name }</span>`,
                    messageTime
                )
            case ChatMessageTypeLogTypeEnum.Decline:
                return t(
                    'chat-message-log-type-4',
                    `<span class="font-medium">${ props.message.log_param_operator_name }</span>`,
                    messageTime
                )
            case ChatMessageTypeLogTypeEnum.Transfer:
                return t(
                    'chat-message-log-type-5',
                    `<span class="font-medium">${ props.message.log_param_from_operator_name }</span>`,
                    `<span class="font-medium">${ props.message.log_param_to_operator_name }</span>`,
                    messageTime
                )
            default:
                return `[Unknown log type: ${ props.message.log_type }]`
        }
    })
</script>

<template>
    <AppDividerWithText>
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="logMessage"></span>
        <!-- eslint-enable vue/no-v-html -->
    </AppDividerWithText>
</template>
