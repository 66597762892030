<script
    lang="ts"
    setup
>
    type Props = {
        search: any
    }

    type Emit = {
        (event: 'update:search', value: any): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const listRef = ref<HTMLDivElement>()

    const { arrivedLeft, arrivedRight } = useScroll(listRef)

    const searchModel = useSyncProp(props, 'search', emit)

    const { t } = useLang()

    const tags = computed<{ text: string }[]>(() => {
        return [
            { text: t('hi') },
            { text: t('goodbye') },
            { text: t('super') },
            { text: t('problem') },
            { text: t('thank-you') },
            { text: t('think') }
        ]
    })

    const scrollList = (direction: 'left' | 'right'): void => {
        const left = direction === 'left'
            ? 0
            : listRef.value.scrollWidth

        listRef.value.scrollTo({
            left,
            behavior: 'smooth'
        })
    }
</script>

<template>
    <div class="relative">
        <div
            ref="listRef"
            class="scrollbar-hide overflow-x-auto flex items-center gap-2 py-4 [&>div]:cursor-pointer max-tablet:px-4"
        >
            <div
                v-if="!arrivedLeft"
                key="arrow-left"
                class="
                    z-[1]
                    absolute
                    left-2
                    tablet:left-0
                    flex
                    bg-white
                    shadow-lg
                    rounded-[50%]
                "
                @click="scrollList('left')"
            >
                <AppIconChevronLeft/>
            </div>

            <AppChip
                key="favorite"
                :active="!search"
                @click="searchModel = ''"
            >
                <AppIconStar
                    size="16"
                    color="#000"
                />
            </AppChip>

            <AppChip
                v-for="tag in tags"
                :key="tag.text"
                :active="searchModel === tag.text"
                @click="searchModel = tag.text"
            >
                {{ tag.text }}
            </AppChip>

            <div
                v-if="!arrivedRight"
                key="arrow-right"
                class="
                    z-[1]
                    absolute
                    right-2
                    tablet:right-0
                    flex
                    bg-white
                    shadow-lg
                    rounded-[50%]
                "
                @click="scrollList('right')"
            >
                <AppIconChevronRight/>
            </div>
        </div>
    </div>
</template>
