<script
    lang="ts"
    setup
>
    type Props = {
        modelValue: any
        error?: string
        type?: string
        name: string
        placeholder: string
        disabled?: boolean
        loading?: boolean
        showStatus?: boolean
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
        (event: 'update:error', value: string): void
    }

    const props = withDefaults(defineProps<Props>(), {
        error: '',
        type: 'text',
        disabled: false,
        loading: false,
        showStatus: false
    })

    const emit = defineEmits<Emit>()

    const fieldContainerRef = ref<ReturnType<typeof defineComponent>>()

    const inputModel = useSyncProp<string>(props, 'modelValue', emit, {
        onSet: () => props.error && emit('update:error', '')
    })
    const errorModel = useSyncProp<string>(props, 'error', emit)
</script>

<template>
    <div class="flex [&>svg]:mt-[9px] [&>svg>path]:transition-[fill_var(--transition-default-duration-with-ease)]">
        <slot
            v-if="$slots['icon']"
            name="icon"
            :attrs="{
                size: 20,
                color: fieldContainerRef?.focused ? '#000' : undefined
            }"
        />
        <!-- eslint-disable max-len -->
        <AppFormFieldContainer
            ref="fieldContainerRef"
            key="input"
            small
            always-active
            :error-message="errorModel"
            :disabled="props.disabled"
            :loading="props.loading"
            class="
                !mb-1
                !ml-2
                [&>div]:border-transparent
                [&>div]:transition-[background-color_var(--transition-default-duration-with-ease),border-color_var(--transition-default-duration-with-ease)]
                [&>div]:focus-within:border-black
                [&>div]:focus-within:!bg-transparent
                [&>div]:hover:bg-[rgba(220,224,227,0.3)]
            "
        >
            <template #default="{ inputClass }">
                <input
                    v-model="inputModel"
                    :name="props.name"
                    :type="props.type"
                    :disabled="props.disabled"
                    :placeholder="props.placeholder"
                    :class="inputClass"
                    autocomplete="off"
                    class="h-auto"
                    @keydown.enter="({ target }) => target.blur()"
                />
            </template>

            <template #suffix>
                <Transition name="fade-transition">
                    <AppIconStatusTrue
                        v-if="props.showStatus"
                        size="16"
                    />
                </Transition>
            </template>
        </AppFormFieldContainer>
        <!-- eslint-enable max-len -->
    </div>
</template>
