<script
    lang="ts"
    setup
>
    import { useChatStore } from '~/stores/chat'
    import getChatTitle from '~/helpers/getChatTitle'

    type Props = {
        showSidebar: boolean
    }

    type Emit = {
        (event: 'update:showSidebar', value: boolean): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const showSidebar = useSyncProp(props, 'showSidebar', emit)

    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat

    const { desktop } = useWindowSize()

    const chatTitle = computed<string>(() => getChatTitle(currentChat))

    const onClickChatTitle = (): void => {
        if (desktop.value) {
            navigateTo({
                name: 'p-pid-chat-kind-cid',
                params: {
                    kind: chatStore.getChatKind(chatStore.currentChat),
                    cid: chatStore.currentCid
                }
            })
        } else {
            showSidebar.value = !showSidebar.value
        }
    }
</script>

<template>
    <div
        class="
            flex
            items-center
            justify-between
            w-full
            h-[var(--chat-header-height)]
            py-2
            px-4
            max-tablet:pl-2
            bg-white
            border-[1px]
            border-t-0
            border-[#F6F7F8]
        "
    >
        <div
            id="chat-conversation-header__sidebar-trigger"
            class="flex items-center gap-2"
        >
            <AppButtonIcon
                small
                class="!flex tablet:!hidden"
                @click="chatStore.removeCurrentCid()"
            >
                <AppIconArrowLeft size="20"/>
            </AppButtonIcon>

            <div
                class="flex items-center gap-2 cursor-pointer"
                @click="onClickChatTitle()"
            >
                <AppAvatar
                    :src="undefined"
                    size="32"
                    class="font-medium"
                >
                    {{ chatTitle[0] }}
                </AppAvatar>

                <div class="truncate max-w-[24vw] text-base font-medium">
                    {{ chatTitle }}
                </div>
            </div>
        </div>

        <ChatConversationHeaderActions/>
    </div>
</template>
