<script
    lang="ts"
    setup
>
    import type { ChatMessage, ChatMessageConfig } from '~/ts/types/chat'

    type Props = {
        message: ChatMessage
        messageConfig: ChatMessageConfig
    }

    const props = defineProps<Props>()
</script>

<template>
    <ChatConversationBodyMessageHead
        v-if="props.messageConfig.in && props.messageConfig.firstInGroup"
        key="head"
        :message="props.message"
        :message-config="props.messageConfig"
    />

    <ChatConversationBodyMessageContent
        key="content"
        :message-config="props.messageConfig"
    >
        <ChatUIEmoji
            big
            :class="[ props.messageConfig.in ? 'mr-2' : 'ml-2' ]"
        >
            {{ props.message.text }}
        </ChatUIEmoji>

        <ChatConversationBodyMessageDetails
            :message="props.message"
            :message-config="props.messageConfig"
        />
    </ChatConversationBodyMessageContent>
</template>
