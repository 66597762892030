<script
    lang="ts"
    setup
>
    import { useChatStore } from '~/stores/chat'

    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat

    const showConfirm = ref<boolean>(false)
    const pending = ref<boolean>(false)

    const disabled = computed<boolean>(() => {
        return chatStore.isCurrentChatNew
            || chatStore.isCurrentChatClosed
            || chatStore.isCurrentChatInTransferProcess
    })

    const closeChat = async (): Promise<void> => {
        if (pending.value) {
            return
        }

        pending.value = true

        const { data, error } = await useApi().chat.chatClose({
            id: currentChat.id
        })

        if (error.value) {
            pending.value = false

            return
        }

        chatStore.insertMessagesIntoChat(currentChat, data.value.messages, { position: 'after' })
        chatStore.updateChat(currentChat.id, data.value, true)

        await chatStore.removeCurrentCid()
        await chatStore.ensureCurrentCid()

        showConfirm.value = false

        useNotify().push({
            type: 'success',
            text: useLang().t('successfully-closed')
        })

        pending.value = false
    }
</script>

<template>
    <slot
        name="activator"
        :open="() => (showConfirm = true)"
        :disabled="disabled"
    />

    <AppConfirm
        v-if="showConfirm"
        :pending="pending"
        @confirm="closeChat()"
        @cancel="showConfirm = false"
    >
        <template #icon>
            <AppIconCheckBold size="34"/>
        </template>

        <template #title>
            {{ $t('end-dialog') }}
        </template>

        <template #body>
            {{ $t('chat-sidebar-close-dialog-hint') }}
        </template>

        <template #confirm-text>
            {{ $t('chat-actions-close') }}
        </template>
    </AppConfirm>
</template>
