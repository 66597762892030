<template>
    <div class="z-[1] absolute flex justify-center top-4 w-full">
        <div class="flex m-auto p-1 bg-[#f6f7f8] rounded-[6px]">
            <AppIconLoading
                size="24"
                :animate="true"
            />
        </div>
    </div>
</template>
