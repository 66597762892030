<script
    lang="ts"
    setup
>
    import type { ChatBusEventUploadPhotoPayload, ChatUploadPhotoModalContext } from '~/ts/types/chat'
    import { ChatMessageKindEnum, ChatMessageTypeEnum } from '~/ts/enums/chat'
    import { useChatStore } from '~/stores/chat'
    import { useUserStore } from '~/stores/user'

    const textareaRef = ref<ReturnType<typeof defineComponent>>()

    const chatStore = useChatStore()
    const userStore = useUserStore()

    const currentChat = chatStore.currentChat

    const { $popupManager } = useNuxtApp()

    const bus = useEventBus()

    const tabModel = ref<ChatMessageKindEnum>(ChatMessageKindEnum.Chat)
    const inputValue = ref<string>('')
    const isNote = computed<boolean>(() => tabModel.value === ChatMessageKindEnum.Note)

    const typingListener = {
        typing: false,
        typingThrottle: 2000,
        typingTimer: undefined,
        stopTimer: undefined,
        send(chatId: number) {
            if (!process.client) {
                return
            }

            this.stop(chatId)

            if (this.typing) {
                return
            }

            this.typing = true

            chatStore.sendTyping(chatId, { typing: true })

            this.typingTimer = setTimeout(() => {
                this.typing = false

                chatStore.sendTyping(chatId, { typing: true })
            }, this.typingThrottle)
        },
        stop(chatId: number, clear = false): void {
            if (!process.client) {
                return
            }

            clearTimeout(this.stopTimer)

            if (clear) {
                this.typing = false

                clearTimeout(this.typingTimer)
            }

            this.stopTimer = setTimeout(() => {
                chatStore.sendTyping(chatId, { typing: false })
            }, clear ? 0 : this.typingThrottle * 2)
        }
    }

    const inputModel = computed<string>({
        get() {
            if (currentChat.editableMessage) {
                return currentChat.editableMessage.editableText
            } else {
                return inputValue.value
            }
        },
        set(value) {
            if (currentChat.editableMessage) {
                currentChat.editableMessage.editableText = value

                return
            }

            inputValue.value = value

            if (value) {
                typingListener.send(currentChat.id)
            } else {
                typingListener.stop(currentChat.id, true)
            }
        }
    })

    const { t } = useLang()

    const textareaPlaceholder = computed<string>(() => {
        return (
            isNote.value
                ? t('chat-conversation-input-note-placeholder')
                : t('write-a-message')
        ) + '...'
    })

    const { focus, setCursorPosition } = useFieldControl(
        textareaRef,
        {
            nestedRefName: 'textareaRef',
            startTyping: true,
            disabled: () => {
                return $popupManager.isModalOpen() || $popupManager.isMenuOpen()
            }
        }
    )

    const sendTextMessage = () => chatStore.sendMessage(
        ChatMessageTypeEnum.Text,
        { text: inputModel.value },
        {
            isNote: isNote.value,
            callbackBeforeInsert: resetInput
        }
    )

    const setTabModel = (value: ChatMessageKindEnum): void => {
        if (tabModel.value === value) {
            return
        }

        tabModel.value = value

        focus()
    }

    const insertIntoInput = (value: string, replaceValue?: boolean): void => {
        if (replaceValue) {
            inputModel.value = value
        } else {
            const textareaEl = textareaRef.value.getTextArea()

            const { selectionStart, selectionEnd } = textareaEl

            inputModel.value = inputModel.value.slice(0, selectionStart) + value + inputModel.value.slice(selectionEnd)

            setCursorPosition(selectionStart + value.length)
        }

        nextTick(() => textareaRef.value.resize())
    }

    const resetInput = (): void => {
        inputModel.value = ''

        typingListener.stop(currentChat.id, true)

        nextTick(() => textareaRef.value.resize())
    }

    const onEnter = (): void => {
        typingListener.stop(currentChat.id, true)

        currentChat.editableMessage ? chatStore.submitEditableMessage() : sendTextMessage()
    }

    const uploadPhoto = (payload: ChatBusEventUploadPhotoPayload): void => {
        if (!userStore.activeSubscription) {
            return
        }

        const { file } = payload

        if (!fileUtil.isSupportedImage(file)) {
            useNotify().push({
                type: 'error',
                text: useLang().t('unsupported-image-type')
            })

            return
        }

        if (!fileUtil.validateFileSize(file)) {
            useNotify().push({
                type: 'error',
                text: useLang().t('file-must-be-less-than-5mb')
            })

            return
        }

        payload.onValidated?.()

        const eventValue: ChatUploadPhotoModalContext = {
            file,
            inputText: inputModel.value,
            onCancel: (text: string) => insertIntoInput(text, true),
            onSubmit(text: string) {
                chatStore.sendMessage(
                    ChatMessageTypeEnum.Image,
                    { file, text },
                    { isNote: isNote.value }
                )
            }
        }

        resetInput()

        bus.emit(BUS_EVENT_CHAT_OPEN_UPLOAD_PHOTO_MODAL, eventValue)
    }

    const busCleaners = [
        bus.on<ChatBusEventUploadPhotoPayload>(BUS_EVENT_CHAT_INPUT_UPLOAD_PHOTO, uploadPhoto),
        bus.on(BUS_EVENT_APP_MODAL_CLOSED, () => !$popupManager.isModalOpen() && focus())
    ]

    watch(() => currentChat?.editableMessage, (value) => {
        nextTick(() => {
            textareaRef.value?.resize()

            tabModel.value = value?.is_note ? ChatMessageKindEnum.Note : ChatMessageKindEnum.Chat
        })
    })

    onBeforeUnmount(() => busCleaners.forEach(cb => cb()))
</script>

<template>
    <div
        :class="[
            `
                max-w-[100vw]
                relative
                flex
                flex-col
                justify-between
                h-full
                w-full
                py-4
                px-2
                bg-white
            `,
            { '!bg-[#fefae8] shadow-[inset_0_1px_1px_#eee5bc]': isNote }
        ]"
    >
        <ChatConversationFooterInputTyping/>

        <ChatConversationFooterInputEditableMessage/>

        <div class="flex w-full ml-2 mb-2">
            <ChatConversationFooterInputTabs
                :model-value="tabModel"
                :disabled="!!currentChat.editableMessage"
                @update:model-value="setTabModel"
            />
        </div>

        <ChatUITextarea
            ref="textareaRef"
            v-model="inputModel"
            :placeholder="textareaPlaceholder"
            class="ml-2"
            @keydown.meta.enter="onEnter()"
        />

        <div class="flex justify-between">
            <ChatConversationFooterInputControls
                :input-model="inputModel"
                :is-note="isNote"
                @insert-into-input="insertIntoInput"
                @focus-input="focus()"
            />

            <ChatConversationFooterInputSubmit
                :input-model="inputModel"
                @submit="sendTextMessage()"
            />
        </div>
    </div>
</template>
